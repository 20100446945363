import React, { useState } from "react"
// import { Link } from "gatsby"
import { EducationAcademyQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  BreadCrumbBanner,
  SubscriptionPanel,
  PrismicRichText,
} from "../../components/shared"
import { Accordion, Card, ListGroupItem } from "react-bootstrap"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    page_title,
    banner_image,
    body,
    page_content,
    page_heading,
  } = EducationAcademyQuery(language)

  const [activeItem, setActiveItem] = useState(1)
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section className="sectionbg education-academy p-top-100 p-bottom-110">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-5">
                  <div className="divider-simple ">
                    <h3 className="text-center" style={{ color: "#1B76BD" }}>
                      {page_title[0].text}
                    </h3>
                    <div>
                      {page_content.map(content => (
                        <p>{content.text}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-styles accordion--one">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {body.map((item, bodyInd) => (
                    <div className="row" key={"bodfy" + bodyInd}>
                      <div className="col-lg-3 p-bottom-40">
                        <img
                          src={item.primary.section_image.url}
                          alt="section"
                        />
                      </div>
                      <div className="col-lg-9 p-bottom-40">
                        <h4>{item.primary.section_title[0].text}</h4>
                        <div className="mb-2">
                          <PrismicRichText
                            data={item.primary.section_description}
                          />
                        </div>
                        <div className=" accordion_one" id="accordion_one">
                          <Accordion defaultActiveKey={1} style={{}}>
                            {item.items.map((section, ind) => (
                              <Card key={"itmesdf" + ind}>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={ListGroupItem}
                                    variant="link"
                                    eventKey={ind + 1}
                                    onClick={() => {
                                      setActiveItem(ind)
                                    }}
                                    className="accordion-question d-flex justify-content-between"
                                  >
                                    {section.rep_title[0].text}
                                    <i
                                      className={`fas arrow-icon fa-chevron-${
                                        activeItem === ind ? "up" : "down"
                                      }`}
                                    ></i>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={ind + 1}>
                                  <Card.Body>
                                    <PrismicRichText data={section.rep_body} />
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
